import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'pagamentos';
const getBancosPagamentoPorId = async ({ idSinf, formaPagamento }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PAGAMENTO_API_KEY } };
    const { data } = await api.get(`${resource}/formasPagamento/${idSinf}`, config);
    const list = data && data.data ? data.data.filter((item) => (item.forma === formaPagamento)) : [];
    return list;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getFormasPagamento = async ({ idSinf, administradora }) => {
  try {
    const config = { params: { administradora }, headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PAGAMENTO_API_KEY } };
    const url = `${resource}/formasPagamento/${idSinf}`;
    const { data } = await api.get(url, config);
    return data && data.data ? data.data : [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getFormasPagamentoPME = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PAGAMENTO_API_KEY } };
    const url = `${resource}/formasPagamento?administradora=qualicorp&pme=true`;
    const { data } = await api.get(url, config);
    return data && data.data ? data.data : [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getBancosPagamentoPorId,
  getFormasPagamento,
  getFormasPagamentoPME,
};
