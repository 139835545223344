import api from "@/services/api";
import AppError from "@/utils/appError";

const resource = "vigencias";
const obterVigenciaPorPlano = async ({ idPlano, idFilial, dataVigenciaHerdada }) => {
  try {
    let filtroVigenciaHerdada = ``;
    if (dataVigenciaHerdada) {
      filtroVigenciaHerdada = `&dataVigenciaHerdada=${dataVigenciaHerdada}`;
    }
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_VIGENCIA_API_KEY } };
    const { data } = await api.get(`${resource}/${idPlano}?idFilial=${idFilial}${filtroVigenciaHerdada}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const obterVigenciaPorPlanoComValorFuturo = async ({
  idPlano, idFilial, dataVigenciaHerdada, beneficiarios, cpfCnpjCorretora, portabilidade, nrProposta, reapresentacao, operadora,
}) => {
  try {
    let filtroVigenciaHerdada = ``;
    if (dataVigenciaHerdada) {
      filtroVigenciaHerdada = `&dataVigenciaHerdada=${dataVigenciaHerdada}`;
    }

    const config = {
      params: {
        cpfCnpjCorretora,
      },
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_VIGENCIA_API_KEY },
    };

    const body = {
      beneficiarios,
      portabilidade: typeof portabilidade === 'boolean' ? portabilidade : undefined,
      nrProposta,
      reapresentacao,
      operadora,
    };

    const { data } = await api.post(
      `${resource}/${idPlano}/busca-avancada?idFilial=${idFilial}${filtroVigenciaHerdada}`,
      body,
      config,
    );
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const consultarVigencia = async ({ uf, operadora }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_VIGENCIA_API_KEY } };
    const { data } = await api.post(`${resource}/lista`, {
      uf,
      operadora,
      rangeDias: 90,
    }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const obterVigenciaPorOperadora = async ({
  entidade, operadora, uf, cidade, dataVigenciaHerdada, beneficiarios, cpfCnpjCorretora, portabilidade, cpfTitular, cnpjOperadora, pme, nrContrato, reapresentacao
}) => {
  try {
    const body = {
      entidade,
      uf,
      cidade,
      cpfCnpjCorretora,
      beneficiarios,
      dataVigenciaHerdada,
      portabilidade: typeof portabilidade === 'boolean' ? portabilidade : undefined,
      cpfTitular,
      cnpjOperadora,
      pme,
      nrContrato,
      reapresentacao
    };

    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_VIGENCIA_API_KEY } };
    const { data } = await api.post(`${resource}/lista/${operadora}`, body, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const obterVigenciaPorEntidadeOperadora = async (entidade, operadora) => {
  try {    
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_VIGENCIA_API_KEY } };
    const { data: response } = await api.get(`${resource}/pessoa-juridica?operadora=${operadora}&entidade=${entidade}`, config);
    return response && response.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  obterVigenciaPorPlano,
  obterVigenciaPorPlanoComValorFuturo,
  consultarVigencia,
  obterVigenciaPorOperadora,
  obterVigenciaPorEntidadeOperadora
};
